import React from "react";
import { formatBalance } from "../../../../Helpers/Pricing";

import { Row, Skeleton, Tooltip } from "antd";
import "./index.scss";
import NotFoundCard from "../../Common/NotFoundCard";
import ErrorCard from "../../Common/ErrorCard";

const DeductedFromWallet = (props) => {
  const {
    data,
    startDate,
    endDate,
    loading,
    error,
    errorCode,
    errorMsg,
  } = props;

  const leftFields = [
    { key: "total_ff_cost", label: "Fulfillment Cost" },
    { key: "total_fs_cost", label: "Forward Shipment Cost" },
    { key: "total_pp_cost", label: "Product Price" },
    { key: "total_cod_fee", label: "COD Fee" },
    { key: "total_management_fee", label: "Management Fee" },
    { key: "total_platform_fee", label: "Convenience Fee" },
    { key: "total_orderamount_gst", label: "Order GST" },
    { key: "total_rto_handling_cost", label: "RTO Handling Fee" },
    { key: "total_rto_shipping_cost", label: "RTO Shipping Cost" },
    { key: "total_verified_cancellations", label: "Verified Cancellations" },
    { key: "total_payment_gateway_charges", label: "Payment Gateway Charges" },
    { key: "total_order_reverse_pickup", label: "Order Reverse Pickup" },
    { key: "total_buyback_credit_pp", label: "Buyback Credit PP" },
  ];

  function amountHandler(amount, color) {
    if (!!amount) {
      return (
        <div className={`delivered-order-head   ${color ? color : "debit"}`}>
          ₹ {formatBalance(amount)}
        </div>
      );
    } else {
      return <div className="delivered-order-head ">0</div>;
    }
  }

  if (loading) {
    return (
      <Row style={{ marginBottom: "30px" }}>
        <Skeleton active />
      </Row>
    );
  }

  // error card
  if (!loading && error) {
    return (
      <div id="fade-in" className="deductedFromWallet">
        <div className="topPerformingProductHead">
          {" "}
          <span className="name">Money Deducted From Wallet</span> |{" "}
          <span>
            {startDate} - {endDate}
          </span>
        </div>

        <ErrorCard errorMsg={errorMsg} errorCode={errorCode} />
      </div>
    );
  }

  if (Object.entries(data).length === 0) {
    return (
      <div id="fade-in" className="deductedFromWallet">
        <div className="topPerformingProductHead">
          {" "}
          <span className="name">Money Deducted From Wallet</span> |{" "}
          <span>
            {startDate} - {endDate}
          </span>
        </div>

        <NotFoundCard
          mainHeading={"Oops! No Data Found."}
          secondHeading={
            <p>
              Looks like there are no data in <span>current Date Range</span>.
            </p>
          }
          thirdHeading={
            <p>
              Please <span>select another Date Range</span> from Date Picker to
              filter data.
            </p>
          }
        />
      </div>
    );
  }
  return (
    <div id="fade-in" className="deductedFromWallet">
      <div className="topPerformingProductHead">
        {" "}
        <span className="name">Money Deducted From Wallet</span> |{" "}
        <span>
          {startDate} - {endDate}
        </span>
      </div>
      <div className="product-heads">
        <div className="name-head">VF Order Charges</div>
        {/* <div className="delivered-order-head"> Pending </div> */}
        <div className="delivered-order-head">Amount</div>
      </div>

      <div className="product-row-wraper">
        {/* <div className="product-row">
          <div className="name-head">Product Price</div>
          {amountHandler(data.pending_pp_cost, "yellow")}
          {amountHandler(data.pp_cost)}
        </div> */}
        {leftFields.map((field, index) => (
          <div
            key={field.key}
            className={`product-row ${
              index === leftFields.length - 1 ? "last-row" : ""
            }`}
          >
            <div className="name-head">{field.label}</div>

            {amountHandler(data[field.key])}
          </div>
        ))}

        {/* <div className="product-row last-row">
          <div className="name-head total">Total</div>
          {amountHandler(data.total_pending, "yellow")}
          {amountHandler(data.total)}
        </div> */}
      </div>
    </div>
  );
};

export default DeductedFromWallet;

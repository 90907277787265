import React from "react";
import { formatBalance } from "../../../../Helpers/Pricing";

import { Row, Skeleton, Tooltip } from "antd";
import GlobalTooltipIcon from "../../Common/GlobalTooltipIcon";
import "./index.scss";
import NotFoundCard from "../../Common/NotFoundCard";
import ErrorCard from "../../Common/ErrorCard";

const AddedInWallet = (props) => {
  const {
    data,
    startDate,
    endDate,
    loading,
    error,
    errorMsg,
    errorCode,
  } = props;

  function amountHandler(amount, color) {
    if (!!amount) {
      if (color === "yellow") {
        return (
          <div className="delivered-order-head yellow">
            ₹ {formatBalance(amount)}
          </div>
        );
      } else {
        return (
          <div className="delivered-order-head credit">
            ₹ {formatBalance(amount)}
          </div>
        );
      }
    } else {
      return <div className="delivered-order-head ">0</div>;
    }
  }

  const topRightFields = [
    { key: "total_cod_income", label: "COD Income" },
    { key: "total_prepaid_income", label: "Prepaid Income" },
    { key: "total_income", label: "Total Income" },
  ];

  if (loading) {
    return (
      <Row style={{ marginBottom: "30px" }}>
        <Skeleton active />
      </Row>
    );
  }

  if (!loading && error) {
    return (
      <div id="fade-in" className="deductedFromWallet">
        <div className="topPerformingProductHead">
          {" "}
          <span className="name">Money Added In Wallet</span> |{" "}
          <span>
            {startDate} - {endDate}
          </span>
        </div>

        <ErrorCard errorMsg={errorMsg} errorCode={errorCode} />
      </div>
    );
  }

  if (Object.entries(data).length === 0) {
    return (
      <div id="fade-in" className="deductedFromWallet">
        <div className="topPerformingProductHead">
          {" "}
          <span className="name">Money Added In Wallet</span> |{" "}
          <span>
            {startDate} - {endDate}
          </span>
        </div>

        <NotFoundCard
          mainHeading={"Oops! No Data Found."}
          secondHeading={
            <p>
              Looks like there are no data in <span>current Date Range</span>.
            </p>
          }
          thirdHeading={
            <p>
              Please <span>select another Date Range</span> from Date Picker to
              filter data.
            </p>
          }
        />
      </div>
    );
  }
  return (
    <div id="fade-in" className="deductedFromWallet">
      <div className="topPerformingProductHead">
        {" "}
        <span className="name">Money Added In Wallet</span> |{" "}
        <span>
          {startDate} - {endDate}
        </span>
      </div>
      <div className="product-heads">
        <div className="name-head">Source</div>

        <div className="delivered-order-head">Amount</div>
      </div>

      <div className="product-row-wraper">
        {topRightFields.map((field, index) => (
          <div
            key={field.key}
            className={`product-row ${
              index === topRightFields.length - 1 ? "last-row" : ""
            }`}
          >
            <div className="name-head">{field.label}</div>
            {amountHandler(data[field.key])}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AddedInWallet;
